import React from 'react';
import { CtaSectionExtended } from 'modules/cta-section-extended';

export const MobileCtaSectionExtended = () => {
    return (
        <CtaSectionExtended title="ctaExtendedTitle">
            <p>
                Create seamless, high-performing mobile experiences with our
                expertise in React Native, Android, and iOS app development
                tailored to your goals.
            </p>
            <ul>
                <li>Free consultation to discuss your mobile app vision</li>
                <li>
                    Discover how cross-platform development with React Native
                    accelerates time-to-market
                </li>
                <li>
                    Expertise in building native apps for Android and iOS with
                    exceptional performance
                </li>
                <li>
                    Streamline build and release processes using Bitrise and
                    Fastlane for efficient deployment
                </li>
                <li>
                    Deliver engaging user experiences with intuitive design and
                    smooth functionality
                </li>
                <li>
                    Ensure app reliability and quality with rigorous testing and
                    optimization
                </li>
            </ul>
        </CtaSectionExtended>
    );
};
